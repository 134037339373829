import React from 'react';
import './HeroYellow.scss';
import Button from '../../components/Button/Button';

function HeroYellow({ header, firstParagraph, secondParagraph, buttonText, buttonLink, additionalClasses = [] }) {
    return (
        <section className={['hero-yellow-section', ...additionalClasses].join(' ')}>
            <h1>{header}</h1>
            {firstParagraph && <p className="hero-yellow-section__paragraph">{firstParagraph}</p>}
            {secondParagraph && <p className="hero-yellow-section__paragraph">{secondParagraph}</p>}
            <a href={buttonLink}>
                <Button additionalClasses="btn--transparent">
                    {buttonText}
                </Button>
            </a>
        </section>
    );
}

export default HeroYellow;
